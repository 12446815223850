import * as React from 'react';
import ShippingMethod from './ShippingMethod';
import { ELEMENTS, EXPERIMENT_C_VARIANTS, STEP } from '../../constants';
import { REGION } from '../../regions';
import ShippingDetailsGroup from '../common/intl/ShippingDetailsGroup/ShippingDetailsGroup';
import { setShippingMethod } from '../../actions/thunks';
import { useStoreContext } from '../../utils/storeUtils';
import { goNextStep, goToStep } from '../../actions/thunks';

interface Props {
  disabledBtnClass: string;
  street: string;
  geoState: string;
}

const ShippingForm = ({ street, geoState, disabledBtnClass }: Props) => {
  const { storeState, dispatch } = useStoreContext();
  const {
    shippingMethods,
    step,
    paymentMethod,
    isBillingPromo,
    expCCartCheckoutCleanup,
  } = storeState;
  const isExperimentC = EXPERIMENT_C_VARIANTS.includes(expCCartCheckoutCleanup);

  const showInactive = step < STEP.SHIP_METHOD;
  const ShippingDetails = ShippingDetailsGroup[REGION];

  const afterSubmit = () => {
    if (paymentMethod == 'paypal' || paymentMethod == 'venmo' || isBillingPromo) {
      dispatch(goToStep(STEP.REVIEW, ELEMENTS.review));
    } else {
      dispatch({ type: 'SPC_GO_STEP_3' });
      dispatch(goNextStep(`#${ELEMENTS.billing}`, -100));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    dispatch(setShippingMethod(afterSubmit));
  };

  const expCClass = isExperimentC && !showInactive ? ' exp-c' : '';
  const modBorderClass = isExperimentC && !showInactive ? '' : ' mod-border';

  return (
    <form className={`spc-wrap${showInactive ? ' mod-inactive' : ''}`} onSubmit={handleSubmit}>
      <div className={`spc-align${modBorderClass}${expCClass}`}>
        <h2
          id={ELEMENTS.shipping}
          tabIndex={-1}
          className={`spc-align-h2${isExperimentC ? ' exp-c' : ''} spc-count`}
          data-stor-id="spc-shipping-method-header"
        >
          {!isExperimentC && '2. '}Shipping Method
        </h2>
        <span className="spc-step">Step 2 of 3</span>
      </div>
      <div
        className={`spc-form${showInactive ? ' mod-inactive' : ''}`}
        data-jest-id="shipping-form-container"
      >
        <div className="spc-shipping">
          <div className={`spc-title${isExperimentC ? ' exp-c' : ''}`}>
            {!isExperimentC && <h3 className="spc-title-h3">SELECT SHIPPING</h3>}
            <ShippingDetails />
          </div>
          <div
            className="spc-input-wrap spc-shipping-div"
            role="radiogroup"
            aria-labelledby={ELEMENTS.shipping}
          >
            {shippingMethods.map(shippingType => (
              <ShippingMethod
                code={shippingType.code}
                title={shippingType.title}
                displayPrice={shippingType.displayPrice}
                {...shippingType}
                key={shippingType.code}
                street={street}
                geoState={geoState}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="spc-next">
            <button
              className={`spc-next-button mod-ok ${disabledBtnClass}`}
              data-stor-id="spc-shipping-method-continue-button"
              data-track-click="checkout_shipping_button"
              type="submit"
            >
              {isExperimentC ? 'Continue to Payment Method' : 'continue to billing'}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ShippingForm;
