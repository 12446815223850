/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ELEMENTS } from '../../../constants';
import { useStore } from '../../../utils/storeUtils';

interface Props {
  date: PaymentInputField;
  className: string;
  errorClassName: string;
  isHosted: boolean;
}

export default function ExpirationDate({ date, className, errorClassName, isHosted }: Props) {
  const label = 'Expiration';
  let sample = isHosted ? 'MM/YY' : 'MM/YYYY';
  let errClass = className;
  if (date.showError) {
    if (date.isEmpty) {
      sample = `${label} is required ${sample}`;
    } else if (!date.isValid) {
      sample = `Invalid ${label} ${sample}`;
    } else {
      sample = `${label} has unknown error ${sample}`;
    }
    errClass = errorClassName;
  }
  const [_, updateStoreState] = useStore();
  const onChange = e => {
    updateStoreState({ expiration: e.target.value });
  };
  const input = isHosted ? (
    <div id={ELEMENTS.ccExp} className={`${errClass} mod-no-top-margin`} />
  ) : (
    <input onChange={onChange} id={ELEMENTS.ccExp} className={`${errClass} mod-no-top-margin`} />
  );
  return (
    <div className="spc-input" style={{ minWidth: '60%' }}>
      <div className="spc-input-container">
        <div className="spc-input-label spc-visually-hidden">
          <label htmlFor="expiration-date" className="spc-input-label-exp">
            <span>{label}</span>
          </label>
        </div>
        {input}
        {date.showError && <p className="validation-error">{sample}</p>}
      </div>
    </div>
  );
}
