/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import BillingForm from './BillingForm';
import BillingInfo from './BillingInfo';
import { BILLING_ADDRESS, BILLING_METHODS, EXPERIMENT_C_VARIANTS } from '../../constants';
import { defaultFieldInfo, parseIsoCountryCode } from '../../utils/i18nFields';
import { fetchStates, fetchLabels, removeSavedPayment } from '../../utils/storeApi';
import { REGION } from '../../regions';
import { Store } from '../../pageSetup';
import { updateStore, UseStoreCallback } from '../../utils/storeUtils';

interface Props {
  countries: Country[];
  editableClass: string;
  isAuthenticated: boolean;
  savedPayment: SavedPayment;
  hasSavedCreditCard: boolean;
  shippingAddress?: Address;
  states: GeoState[];
  showForm: boolean;
  isComplete: boolean;
  isEditHostedFields: boolean;
  setIsEditHostedFields: any;
  switchToCustomAddress: (addressMode: string) => void;
  setBillingState: any;
  billingState: Billing;
  isCustomAddressValidated: boolean;
  fieldInfo: typeof defaultFieldInfo;
  setFieldInfo: any;
  setSavePayment: any;
  handleSubmit: (ccInfo) => void;
  isApiUp: boolean;
  hasTotalHealthUpgrade: boolean;
  displayRequiredError: boolean;
}

interface State {
  disableCountrySelector: boolean;
  saveCreditCardOptIn: boolean;
  states: GeoState[];
  isCustomAddress?: boolean;
}

class BillingContainer extends React.Component<Props, State> {
  static contextType = Store;

  context: { storeState: StoreState; dispatch: any };
  updateStore: UseStoreCallback;

  CUSTOM_ADDRESS_SOURCES: number[];

  state = {
    disableCountrySelector: false,
    saveCreditCardOptIn: false,
    states: this.props.states,
  };

  componentDidMount() {
    this.updateStore = updateStore.bind(this, this.context.dispatch);
  }

  removeSavePayment = () => {
    const { savedPayment } = this.props;
    const successCallback = () => {
      this.updateStore({
        paymentMethod: 'credit_card',
      });
      this.props.setBillingState({
        ...this.props.billingState,
        addressSource: BILLING_ADDRESS.SHIPPING,
      });
    };
    const token = savedPayment ? savedPayment.token : '';
    removeSavedPayment(token, successCallback, () => {});
  };

  handleChange = event => {
    const method = event.target.value;
    if (method === undefined) {
      return;
    }

    const { setIsEditHostedFields, setBillingState, savedPayment, billingState } = this.props;

    const newBilling: Partial<Billing> = {
      addressSource: BILLING_ADDRESS.SHIPPING,
      customAddress: { country: parseIsoCountryCode(REGION) },
      saveCreditCardOptIn: false,
    };

    setIsEditHostedFields(method !== BILLING_METHODS.SAVED_PAYMENT);
    if (method === BILLING_METHODS.SAVED_PAYMENT) {
      newBilling.addressSource = BILLING_ADDRESS.SAVED;
      // saved payment currently consists of both payment and billing address flattened together.
      newBilling.customAddress = Object.assign({}, savedPayment, { address2: '', company: '' });
    }
    setBillingState({ ...billingState, ...newBilling, method });
  };

  handleFetchedStates = states => {
    this.setState({ states, disableCountrySelector: false });
  };

  handleFetchedLabels = fieldInfo => {
    this.props.setFieldInfo({ ...this.props.fieldInfo, ...fieldInfo });
  };

  handleFieldChange = event => {
    const customAddress = Object.assign({}, this.props.billingState.customAddress, {
      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'country') {
      this.setState({ disableCountrySelector: true });
      fetchStates(event.target.value, this.handleFetchedStates);
      fetchLabels(event.target.value, this.handleFetchedLabels);
      customAddress.state = '';
    }

    this.props.setBillingState({ ...this.props.billingState, customAddress });
  };

  render() {
    const {
      countries,
      editableClass,
      isAuthenticated,
      savedPayment,
      hasSavedCreditCard,
      shippingAddress,
      showForm,
      isComplete,
      setIsEditHostedFields,
      isEditHostedFields,
      isApiUp,
      hasTotalHealthUpgrade,
      displayRequiredError,
    } = this.props;

    const { addressSource, customAddress, lastFour, method } = this.props.billingState;

    if (isComplete) {
      const editableClassName = method === 'applepay' ? ' mod-hide' : editableClass;
      return (
        <BillingInfo
          addressSource={addressSource}
          cardType={this.props.billingState.cardType}
          customAddress={customAddress}
          editableClass={editableClassName}
          lastFour={lastFour}
          method={method}
          setEditHostedFields={setIsEditHostedFields}
          switchToCustomAddress={this.props.switchToCustomAddress}
          {...{
            savedPayment,
            shippingAddress,
            hasSavedCreditCard,
          }}
        />
      );
    }
    if (!showForm) return <div />;

    const isExperimentC = EXPERIMENT_C_VARIANTS.includes(
      this.context.storeState.expCCartCheckoutCleanup,
    );

    return (
      <BillingForm
        addressSource={addressSource}
        countries={countries}
        customAddress={customAddress}
        disableCountrySelector={this.state.disableCountrySelector}
        fieldInfo={this.props.fieldInfo}
        handleSubmit={this.props.handleSubmit}
        handleChange={this.handleChange}
        handleFieldChange={this.handleFieldChange}
        isCustomAddressValidated={this.props.isCustomAddressValidated}
        isEditHostedFields={isEditHostedFields}
        method={method}
        shippingAddress={shippingAddress}
        saveCreditCardOptIn={this.props.billingState.saveCreditCardOptIn}
        setEditHostedFields={setIsEditHostedFields}
        setSavePayment={this.props.setSavePayment}
        states={this.state.states}
        removeSavePayment={this.removeSavePayment}
        hasSavedCreditCard={hasSavedCreditCard}
        switchToCustomAddress={this.props.switchToCustomAddress}
        paypalEnabled={this.context.storeState.enablePaypal}
        venmoEnabled={this.context.storeState.enableVenmo}
        isExperimentC={isExperimentC}
        {...{
          countries,
          isAuthenticated,
          savedPayment,
          shippingAddress,
          isApiUp,
          hasTotalHealthUpgrade,
          displayRequiredError,
        }}
      />
    );
  }
}

export default BillingContainer;
