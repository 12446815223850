import React from 'react';
import Logo from '../Svgs/Logo';
import { URLS } from '../../regions';

interface Props {
  logoUrl?: string;
}

const Header = ({ logoUrl = URLS.HOME }: Props) => {
  return (
    <header className="sd-elevation-2">
      <div className="spc-header" data-test-id="spc-header">
        <Logo height="50px" width="73px" url={logoUrl} />
      </div>
    </header>
  );
};

export default Header;
