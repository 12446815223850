import React, { useState, useEffect } from 'react';
import { useStoreContext } from '../../utils/storeUtils';
import { BILLING_METHODS, EXPERIMENT_C_VARIANTS, STEP } from '../../constants';
import { ELEMENTS } from '../../constants';
import Billing from '.';
import { goToStep } from '../../actions/thunks';
import { updateGlobalStore } from '../../actions/actions';
import { BILLING_ADDRESS } from '../../constants';
import { getListOfInvalidFields } from '../../utils/getListOfInvalidFields';
import { refineAddressValidation, defaultFieldInfo } from '../../utils/i18nFields';
import { REGION } from '../../regions';
import debounce from '../../utils/debounce';
import { DEBOUNCE_TIME } from '../../constants';
import { basicAddressFieldRequirements } from '../../utils/getListOfInvalidFields';
import { spcStep3Complete } from '../../actions/actions';
import { goNextStep } from '../../actions/thunks';
import dataLayer from '../../utils/dataLayer';

const ADDRESS_MODES: { [x: string]: { default: number; custom: number } } = {
  credit_card: {
    default: BILLING_ADDRESS.SHIPPING,
    custom: BILLING_ADDRESS.CUSTOM,
  },
  saved_payment: {
    default: BILLING_ADDRESS.SAVED,
    custom: BILLING_ADDRESS.SAVED_CUSTOM,
  },
};

const CUSTOM_ADDRESS_SOURCES = [BILLING_ADDRESS.SAVED_CUSTOM, BILLING_ADDRESS.CUSTOM];

const SpcBillingContainer = () => {
  const { storeState, dispatch } = useStoreContext();

  const {
    billingStates,
    address,
    editableClass,
    billing,
    billingCountries,
    account,
    hasSavedCreditCard,
    step,
    isBillingPromo,
    isApiUp,
    hasTotalHealthUpgrade,
    expCCartCheckoutCleanup,
  } = storeState;

  const [billingState, setBillingState] = useState(billing);
  const [fieldInfo, setFieldInfo] = useState(defaultFieldInfo[REGION]);
  const [isCustomAddressValidated, setIsCustomAddressValidated] = useState(false);
  const [isEditHostedFields, setIsEditHostedFields] = useState(
    billingState.method === 'credit_card',
  );
  const [displayRequiredError, setDisplayRequiredError] = useState(false);

  const showBillingForm = step == STEP.BILLING;
  const billingIsComplete = step > STEP.BILLING;
  const displayClass = !(showBillingForm || billingIsComplete) ? ' mod-inactive' : '';

  // Can be removed by switching to react-forms
  const checkCustomAddressValid = debounce(() => {
    const useCustomAddress = CUSTOM_ADDRESS_SOURCES.includes(billingState.addressSource);
    let isCustomAddressValidated = false;
    if (useCustomAddress) {
      const issues = getListOfInvalidFields(
        billingState.customAddress,
        refineAddressValidation(fieldInfo),
      );
      isCustomAddressValidated = issues.length === 0;
    }
    setIsCustomAddressValidated(isCustomAddressValidated);
  }, DEBOUNCE_TIME);

  // Can be removed by switching to react-forms. Check the custom address is valid every time the country
  // changes because the validation rules change by country
  useEffect(() => {
    checkCustomAddressValid();
  }, [
    billingState.addressSource,
    ...Object.keys(basicAddressFieldRequirements).map(key => billingState.customAddress[key]),
  ]);

  const switchToCustomAddress = (addressMode: 'default' | 'custom') => {
    const addressModes = ADDRESS_MODES[billingState.method] || ADDRESS_MODES['credit_card'];

    const addressSource = addressModes[addressMode];

    let { customAddress } = billingState;
    const startWithSavedCustom =
      addressSource === BILLING_ADDRESS.SAVED_CUSTOM ||
      (addressSource === BILLING_ADDRESS.CUSTOM && hasSavedCreditCard);
    if (startWithSavedCustom) {
      // saved payment currently consists of payment data and address data flattened together
      const paymentMethod = account.paymentMethods.length ? account.paymentMethods[0] : null;
      customAddress = Object.assign({}, paymentMethod, {
        address2: '',
        company: '',
      });
    }
    setBillingState({ ...billingState, addressSource, customAddress });
  };

  const setSavePayment = saveCreditCardOptIn => {
    setDisplayRequiredError(false);
    setBillingState({ ...billingState, saveCreditCardOptIn });
    if (hasTotalHealthUpgrade && !saveCreditCardOptIn) {
      const { cart } = storeState;
      dataLayer.totalHealthAcceptSavePayment(cart.kits[0].alias, cart.kits[0].discountPrice);
    }
  };

  const handleSubmit = ccInfo => {
    setBillingState({ ...billingState, ...ccInfo });
    setBillingState(state => {
      dispatch(updateGlobalStore({ billing: state, paymentMethod: state.method }));
      return state;
    });

    const isNewCreditCard = billingState['method'] === BILLING_METHODS.CREDIT_CARD;
    const isEditSavedPayment =
      billingState['method'] === BILLING_METHODS.SAVED_PAYMENT && isEditHostedFields;
    if (
      hasTotalHealthUpgrade &&
      (isNewCreditCard || isEditSavedPayment) &&
      !billingState['saveCreditCardOptIn']
    ) {
      setDisplayRequiredError(true);
      return;
    }
    setDisplayRequiredError(false);

    dispatch(spcStep3Complete);
    dispatch(goNextStep(`#${ELEMENTS.review}`, -130, true));
  };

  const isExperimentC = EXPERIMENT_C_VARIANTS.includes(expCCartCheckoutCleanup);

  const getEditButton = () => {
    if (billingIsComplete)
      return (
        <button
          className={`spc-button-edit${editableClass}`}
          onClick={e => {
            e.stopPropagation();
            dispatch(goToStep(STEP.BILLING));
            setIsEditHostedFields(false);
            switchToCustomAddress('default');
            dispatch(
              updateGlobalStore({
                nonCC: false,
                paymentMethod: account.paymentMethods.length
                  ? 'saved_payment'
                  : billingState.method,
              }),
            );
          }}
          type="button"
        >
          {isExperimentC ? 'Edit' : 'EDIT'}
        </button>
      );
    return <span className="spc-step">Step 3 of 3</span>;
  };

  if (isBillingPromo) return null;

  return (
    <div className="spc-outer">
      <div className={`spc-wrap${displayClass}`}>
        <div className={`spc-align${isExperimentC ? ' exp-c' : ' mod-border'}`}>
          <h2
            id={ELEMENTS.billing}
            tabIndex={-1}
            className={`spc-align-h2${isExperimentC ? ' exp-c-billing' : ''} spc-count`}
            data-stor-id="spc-billing-header"
          >
            {isExperimentC ? 'Payment Method' : '3. Billing'}
          </h2>
          {getEditButton()}
        </div>
        <Billing
          countries={billingCountries}
          isAuthenticated={account.isAuthenticated}
          isCustomAddressValidated={isCustomAddressValidated}
          savedPayment={account.paymentMethods.length ? account.paymentMethods[0] : null}
          hasSavedCreditCard={hasSavedCreditCard}
          shippingAddress={address}
          states={billingStates}
          showForm={showBillingForm}
          isComplete={billingIsComplete}
          isEditHostedFields={isEditHostedFields}
          setIsEditHostedFields={setIsEditHostedFields}
          switchToCustomAddress={switchToCustomAddress}
          billingState={billingState}
          setBillingState={setBillingState}
          setFieldInfo={setFieldInfo}
          fieldInfo={fieldInfo}
          handleSubmit={handleSubmit}
          setSavePayment={setSavePayment}
          {...{
            editableClass,
            isApiUp,
            hasTotalHealthUpgrade,
            displayRequiredError,
          }}
        />
      </div>
    </div>
  );
};

export default SpcBillingContainer;
