'use strict';

var parse_discount_info = require('./shlex').parse_discount_info;
var set_upsell_flags = require('./shlex').set_upsell_flags;
var syncError = require('./syncError').syncError;
var _ = require('lodash');

var gtm_trackAddToCart = require('./gtm').trackAddToCart;
var gtm_trackRemoveFromCart = require('./gtm').trackRemoveFromCart;
var gtm_trackUpgradeInCart = require('./gtm').trackUpgradeInCart;
var gtm_trackDowngradeInCart = require('./gtm').trackDowngradeInCart;
var gtm_trackQuantityChange = require('./gtm').trackQuantityChange;

var cartv2_initialize = require('./cartv2').initialize;
var cartv2_scrolling = require('./cartv2').cartv2_scrolling;

const HTTP_404 = 404;

var cartplus = require('./cartplus');
var emailCaptureTracker = require('../email_capture_tracker');
var Kit = require('./kits').Kit;
var kits = [];

var EventEmitter = require('events').EventEmitter;
var eventEmitter = new EventEmitter();
eventEmitter.on('validate', validate);
eventEmitter.on('kit:removed', update_cart);
eventEmitter.on('kit:added', update_cart);
eventEmitter.on('loading', show_loader_overlay);
eventEmitter.on('loading:stop', hide_loader_overlay);
eventEmitter.on('kit:removed', update_kit_counts);
eventEmitter.on('kit:added', update_kit_counts);
eventEmitter.on('syncError', syncError);

var $checkoutButton = $('.submit');
var $payPalCheckoutButton = $('.js-paypal-button');
var $applePayButton = $('.js-applepay-button');
var $venmoPayButton = $('.js-venmo-button');
var $submitButtons = $('.submit, .js-paypal-button, .js-applepay-button, .js-venmo-button');
var $payPalForm = $('.js-cart-form.paypal');
var $applePayForm = $('.js-cart-form.applepay');
var $venmoPayForm = $('.js-cart-form.venmo');
var payPalFormEnabled = false;
var applePayFormEnabled = false;
var venmoPayFormEnabled = false;


function _pseudonym(alias) {
    const pseudonyms = {
        prepaid_subscription_kit: 'prepaid',
        us_exome_health_subs_12_mo_prepaid: 'total-health',
    }

    return pseudonyms[alias] ?? alias;
}

function enableSubscriptionUpsell() {
    var $healthUpsell = $('.js-update-at-to-ha');
    var $prepaidUpsell = $('.js-update-ha-to-prepaid');
    $prepaidUpsell.off();
    $healthUpsell.off();
    if (!$prepaidUpsell.length && !$healthUpsell) {
        // no upsell widgets for attaching listeners. Bail.
        return;
    }

    function upgrade(url) {
        eventEmitter.emit('loading');
        $.ajax({
            type: 'POST',
            url: url,
            dataType: "json",
            error: function (data) {
                if (data.status == HTTP_404) {
                    eventEmitter.emit('loading:stop');
                    eventEmitter.emit('syncError');
                }
            }
        }).done(function(data) {
            eventEmitter.emit('kit:added', data);
            gtm_trackUpgradeInCart(data);
        });
    }

    function downgrade(url) {
        eventEmitter.emit('loading');
        $.ajax({
            type: 'POST',
            url: url,
            dataType: "json",
            error: function (data) {
                if (data.status == HTTP_404) {
                    eventEmitter.emit('loading:stop');
                    eventEmitter.emit('syncError');
                }
            }
        }).done(function(data) {
            eventEmitter.emit('kit:removed', data);
            gtm_trackDowngradeInCart(data);
        });
    }

    function buildListener(e, action) {
        e.click(function(e) {
            let $target = $(e.target);
            if ($target.is(':checked')) {
                upgrade($target.data('kit-url') + action + '/');
            } else {
                downgrade($target.data('kit-url') + 'downgrade/');
            }
            return false;
        });
    }

    buildListener($prepaidUpsell, 'prepaid');
    buildListener($healthUpsell, 'health');
}

var applePayInstance;
var applePaySession;

var globalTotal = parseFloat($('input#initial-cart-total').val()) || 0;

var SHIPPING_CODE = {
    matrixrate_standard: 'Standard',
    matrixrate_intermediate: 'Expedited',
    matrixrate_express: 'Express',
};

var ANCESTRY_BOOK_SHIPPING_PREFIX = "book";

var REGION = {
  US: 'en-us',
  CA: 'en-ca',
  GB: 'en-gb',
  EU: 'en-eu',
  INT: 'en-int',
};

var STORE_NAME = '23AndMe';

$checkoutButton.click(function() {
    validate(true);
});

function activateSubmitButtons (isDisabled) {
    setSubmitButtons(isDisabled);
    $checkoutButton.removeClass('button-disabled');
    $payPalCheckoutButton.removeClass('paypal-disabled');
}

function deactivateSubmitButtons (isDisabled) {
    setSubmitButtons(isDisabled);
    $checkoutButton.addClass('button-disabled');
    $payPalCheckoutButton.addClass('paypal-disabled');
}

function setSubmitButtons (isDisabled) {
    $submitButtons.prop('disabled', isDisabled);
}

function show_loader_overlay(){
    $('#loading-overlay').addClass('is-active');
}

function hide_loader_overlay(){
    // delay hiding?
    setTimeout(function(){
        $('#loading-overlay').removeClass('is-active');
    }, 0);
}

function is_total_health(item) {
    const total_health_skus = [
        'US-KIT-EXOME-CLINIC-PREPAID',
        'US-KIT-EXOME-CLINIC-PREPAID-MSRP899-RENEW399',
        'US-KIT-EXOME-CLINIC-PREPAID-MSRP599-RENEW399',
        'US-KIT-EXOME-CLINIC-PREPAID-MSRP899-RENEW899',
        'US-KIT-EXOME-CLINIC-PREPAID-MSRP499-RENEW499'
    ]
    return total_health_skus.includes(item.sku);
}

function is_ancestry_kit(item){

    const ancestry_skus = [
        'US-KIT-V4-ANC-2016',
        'US-KIT-V4-ANC-2016-MSRP109',
        'US-KIT-V4-ANC-2016-MSRP119',
        'CA-KIT-ANC',
        'GB-KIT-ANC',
        'EU-KIT-ANC',
        'INT-KIT-ANC',
    ];

    return ancestry_skus.includes(item.sku);
}

function is_health_kit(item) {
    const health_skus = [
        'US-KIT-PGS',
        'CA-KIT-PGS',
        'GB-KIT-PGS',
        'EU-KIT-PGS',
    ];

    return health_skus.includes(item.sku);
}

function upgrades_to_health(item) {
    const sku = [
        "US-KIT-V4-ANC-2016",
        "CA-KIT-ANC",
        "GB-KIT-ANC",
        "EU-KIT-ANC",
    ];
    return sku.includes(item.sku);
}

function upgrades_to_prepaid(item) {
    const sku = [
        'US-KIT-PGS',
        'GB-KIT-PGS',
        'CA-KIT-PGS',
        'US-KIT-PGS-MSRP219',
        'US-KIT-PGS-MSRP229',
        'US-KIT-PGS-MSRP249',
        'US-KIT-PGS-MSRP299',
    ];
    return sku.includes(item.sku);
}

function is_health_prepaid_sub(item){
    const health_prepaid_sku = [
        'US-HEALTH-SUBS-12-MO-PREPAID',
        'GB-HEALTH-SUBS-12-MO-PREPAID',
        'CA-HEALTH-SUBS-12-MO-PREPAID',
        'US-HEALTH-SUBS-12-MO-PREPAID-MSRP288',
        'US-HEALTH-SUBS-12-MO-PREPAID-MSRP298',
        'US-HEALTH-SUBS-12-MO-PREPAID-MSRP318',
        'US-HEALTH-SUBS-12-MO-PREPAID-MSRP368',
        'US-HEALTH-SUBS-12-MO-PREPAID-MSRP268',
        'CA-HEALTH-SUBS-12-MO-PREPAID-MSRP348',
        'GB-HEALTH-SUBS-12-MO-PREPAID-MSRP208'
    ];
    return health_prepaid_sku.includes(item.sku);
}

function is_fda_region(){
    const regions = [ REGION.GB, REGION.CA, REGION.EU, ];
    return is_domestic_region() || regions.includes(TTAM.LANGUAGE_CODE);
}

function is_domestic_region(){
    return TTAM.LANGUAGE_CODE === REGION.US;
}

function is_international(){
    return TTAM.LANGUAGE_CODE === REGION.INT;
}

function language_code(){
    var lc = TTAM.LANGUAGE_CODE.split('-');
    lc[1] = lc[1].toUpperCase();
    return lc.join('-');
}

function is_click_or_key_press(event) {
    // If event is an Enter or Space key press,
    // or is a click, then return true.
    var is_enter_key_pressed = event.type == 'keydown' && event.keyCode == '13';
    var is_space_key_pressed = event.type == 'keydown' && event.keyCode == '32';
    var is_click = event.type == 'click';
    return is_enter_key_pressed || is_space_key_pressed || is_click;
}

function switch_to_empty_cart(){
    var kit_list = $('.kit-list');
    kit_list.before(
        _.template($('#empty-cart-template').html())
    ).remove();
    $('.cart-header').parent().remove();

    if (!is_domestic_region()){

        $('.cart-details').hide();
        $('.payment-security-row').hide();
    }

    initKits();
}

function get_emptied_kit_list(){
    var kit_list = $('.kit-list');
    if (kit_list.length == 0){
        $('.cart-empty').after(
            _.template($('#kit-list-template').html())
        ).remove();
        kit_list = $('.kit-list');
        if (!is_domestic_region()){
            $('.cart-details').show();
            $('.payment-security-row').show();
        }
    } else {
        kit_list.empty();
    }
    return kit_list;
}

function rebuild_totals(data_totals, plan){
    data_totals = data_totals || {};
    plan = plan || false;

    data_totals.plan_total_discounted_price = plan ? plan.total_discounted_price : null;
    data_totals.plan_price = plan ? plan.price : null;
    data_totals.plan_frequency = plan ? plan.frequency : null;
    data_totals.plan_discounted_price = plan ? plan.discounted_price : null;
    data_totals.plan_discount_amount_number = plan ? plan.discount_amount_number : null;
    data_totals.plan_total_amount_saved = plan ? plan.total_amount_saved : null;
    data_totals.plan_is_sub_free = plan ? plan.is_sub_free : false;

    var totals_container = $('#totals-container');
    totals_container.empty();

    var totals = $(_.template($('#template-totals').html(), data_totals));
    totals_container.append(totals);

    var totals_container_mobile = $('#totals-container-mobile');
    totals_container_mobile.empty();

    var totals_mobile = $(_.template($('#template-totals-mobile').html(), data_totals));
    totals_container_mobile.append(totals_mobile);
}

function update_submission_state(has_kits){
    has_kits = has_kits || false;
    activateSubmitButtons(!has_kits);
}

function update_marketing_banner(total, health, ancestry) {
    health = health || 0;
    ancestry = ancestry || 0;
    $('.promo-banner-wrapper').toggle(total > 0);
    $('.js-fathers-day-2020-ab1').toggle(total == 1 && health == 1);
    $('.js-fathers-day-2020-ab2').toggle(total > 1 || ancestry == 1);
}

function update_fda_banner(show){
    if (is_fda_region()){
        show = show || false;
        if (show){
            $('.fda-banner.mod-partner').removeClass('hide-for-cart');
        } else {
            $('.fda-banner.mod-partner').addClass('hide-for-cart');
        }
    }
}

function update_discount_promo(show){
    if (!show){
        $('#discount-promo-notification').addClass('hide-for-cart');
    } else {
        $('#discount-promo-notification').removeClass('hide-for-cart');
    }
}

function fix_add_kit_button_color(){
    if (!is_domestic_region()){
        $('.js-add-kit').addClass('force-button-color');
        $(window).on('mousemove.afterhack', function(){
            $('.js-add-kit').removeClass('force-button-color');
            $(window).unbind('mousemove.afterhack');
        });
    }
}

function update_cart(data){
    // resets active-button state after button clicked
    fix_add_kit_button_color();

    Tipped.clearAjaxCache();

    // update global cart total
    globalTotal = parseFloat(data.totals.items_total_with_discount);

    // since these events are per-kit, shut them off before clearing the cart.
    // because the elements won't exist after that.
    $('.js-update-ha-to-prepaid').off();
    $('.js-toggle-veil').off('click', toggleVeil);

    if (data.kits.length > 0){
        // some preprocessing
        var ancestry_count = 0;
        var health_count = 0;
        for (var i = 0; i < data.kits.length; i++){
            if (is_ancestry_kit(data.kits[i])) {
                ancestry_count++;
            } else if (is_health_kit(data.kits[i])) {
                health_count++;
            }
        }

        // prepare the display for list of products
        var kit_list = get_emptied_kit_list();
        var kit_template = $('#kit-in-cart-template').html();

        // populate the product list
        $.each(data.kits, function(key, item){
            item.region = TTAM.LANGUAGE_CODE;
            item.is_ancestry = is_ancestry_kit(item);
            item.upgrades_to_prepaid = upgrades_to_prepaid(item);
            item.upgrades_to_health = upgrades_to_health(item);
            item.is_health_prepaid_sub = is_health_prepaid_sub(item);
            item.is_total_health = is_total_health(item);
            item.prepaid_upsell_option = TTAM.show_prepaid_upsell;
            item.cart_totals_discounted = data.totals.discounted;
            item.plan_price = data.plan.price;
            item.plan_frequency = data.plan.frequency;
            item.plan_discounted_price = data.plan.discounted_price;
            item.plan_discount_amount = data.plan.discount_amount;
            item.plan_discount_amount_number = data.plan.discount_amount_number;
            item.plan_kit_component_price = data.plan.kit_component_price;
            item.plan_kit_component_price_number = data.plan.kit_component_price_number;
            item.plan_is_sub_free = data.plan.is_sub_free;
            item.discount_info = parse_discount_info(item.discount_label);
            set_upsell_flags(item);
            item.suppress_prepaid_upsell = data.suppress_prepaid_upsell;
            item.pseudonym = _pseudonym(item.alias);

            var cart_item = $(_.template(kit_template, item));
            cart_item.find('.currency').formatCurrency({region: language_code()});
            cart_item.find('.discount-currency').formatCurrency({region: language_code()});
            cart_item.find('.item-price .price').formatCurrency({region: language_code()});
            kit_list.append(cart_item);
        });

        // update non-product UI elements
        rebuild_totals(data.totals, data.plan);
        update_fda_banner(health_count > 0);
        update_marketing_banner(data.kits.length, health_count, ancestry_count);

        update_discount_promo(!TTAM.hide_multi_kit_discount);
        update_submission_state(true);

        // event handling
        initKits();
        enableSubscriptionUpsell();

        $('.js-toggle-veil').on('click', toggleVeil);

        $payPalForm.toggle(payPalFormEnabled);
        $applePayForm.toggle(applePayFormEnabled);
        $applePayButton.attr('aria-disabled', false);
        $venmoPayButton.attr('aria-disabled', false);
    } else {
        rebuild_totals(data.totals);
        update_fda_banner();
        update_discount_promo(!TTAM.hide_multi_kit_discount);
        update_submission_state();
        switch_to_empty_cart();
        update_marketing_banner(0);
        $payPalForm.toggle(payPalFormEnabled);
        $applePayForm.toggle(applePayFormEnabled);
        $applePayButton.attr('aria-disabled', true);
        $venmoPayButton.attr('aria-disabled', true);
    }

    enable_control_panel();
    hide_loader_overlay();
}

function update_kit_counts(data) {
    var kitCounts = {};

    // count kits in cart
    data.kits.forEach(function(kit) {
        let alias = kit.alias;
        if (is_health_prepaid_sub(kit)) {
            alias = 'prepaid_subscription_kit';
        } else if (is_total_health(kit)) {
            alias = 'total_health';
        }

        if (typeof kitCounts[alias] === 'number') {
            kitCounts[alias] += 1;
        } else {
            kitCounts[alias] = 1;
        }
    });

    // update global
    Object.keys(TTAM.kit_counts).forEach(function(kitAlias) {
        TTAM.kit_counts[kitAlias] = kitCounts[kitAlias] || 0;
    });

    update_email_capture();
}

function update_email_capture() {
    // update email capture data in Exact Target
    emailCaptureTracker.trackAddToCart(
        emailCaptureTracker.getAddToCartProduct(TTAM.kit_counts)
    );
}

function validate (showErrors) {
    // Validates all forms and enables continue button if successful.
    deactivateSubmitButtons(false);

    // Validate optional agreement form
    var agree = $('#id_agreement');
    if (agree.length > 0 && !agree.is(':checked')) {
        return;
    }

    // If we get here, everything's good!
    activateSubmitButtons(false);
    update_submission_state(kits.length > 0);
    return true;
}

function displayFullCartError() {
    var errorModal = $('#js-full-cart-error');
    errorModal.css('display', 'block');

    $('#js-full-cart-error-close').bind('click', function() {
        errorModal.css('display', 'none');
        $(this).unbind('click');
    });

    $(window).bind('click', function(e) {
        errorModal.css('display', 'none');
        $(window).unbind('click');
    });
}

function disable_add_button(alias) {
    const $button = $('#button-add-' + alias + '-kit');
    if ($button) {
        $button
            .addClass('is-disabled')
            .removeClass('js-add-' + alias + '-kit')
            .attr('aria-disabled', 'true')
            .attr('tabindex', -1);

        $button.off();
    }
}

function disable_remove_button(alias) {
    const $button = $('#button-remove-' + alias + '-kit');
    if ($button) {
        $button
            .addClass('is-disabled')
            .removeClass('js-remove-' + alias + '-kit')
            .attr('aria-disabled', 'true')
            .attr('tabindex', -1);

        $button.off();
    }
}

function enable_add_button(alias) {
    const pseudonym = _pseudonym(alias);
    const $button = $('#button-add-' + pseudonym + '-kit');
    if ($button) {
        const add_kit = function(alias) {
            eventEmitter.emit('loading');
            const aliases = {
                'health': 'health',
                'ancestry': 'ancestry',
                'prepaid': 'prepaid_subscription_kit',
                'total-health': 'us_exome_health_subs_12_mo_prepaid',
            };
            $.ajax({
                type: 'POST',
                url: $('#add-kit-form').attr('action'),
                data: {alias: aliases[alias]},
                dataType: "json",
                error: function (jqXHR) {
                    hide_loader_overlay();
                    if (jqXHR.responseText.includes('MagentoCartOverfilledError')) {
                        displayFullCartError();
                    }
                },
            }).done(function(data) {
                eventEmitter.emit('kit:added', data);
                gtm_trackQuantityChange(alias, 'add');
                gtm_trackAddToCart(data);
            });
        };

        $button
            .removeClass('is-disabled')
            .addClass('js-add-' + pseudonym + '-kit')
            .attr('aria-disabled', 'false')
            .attr('tabindex', 0);

        $button.off();
        $button.on('click keydown', function (e) {
            if (is_click_or_key_press(e)) {
                add_kit(alias);
            }
        });
    }
}

function get_alias_query(alias) {
    const pseudonym = _pseudonym(alias);
    return '.js-'+pseudonym+'-kit-item'
}

function enable_remove_button(alias) {
    /*
     remove button impacts different SKUs
     consider: PREPAID = HEALTH+ANCESTRY with CHECKED UPSELL
     */
    const pseudonym = _pseudonym(alias);
    const $button = $('#button-remove-' + pseudonym + '-kit');
    const $kits = get_alias_query(alias);
    const $kit = $($kits).filter(':last');
    if ($button && $kit.length > 0) {
        const remove_kit = function() {
            eventEmitter.emit('loading');
            const payload = {};
            $.ajax({
                type: 'DELETE',
                url: $kit.data('kit-url'),
                data: {},
                dataType: "json",
                error: function (data) {
                    if (data.status === HTTP_404) {
                        eventEmitter.emit('loading:stop');
                        eventEmitter.emit('syncError');
                    }
                },
            }).done(function(data) {
                eventEmitter.emit('kit:removed', data);
                gtm_trackQuantityChange($kit.data('alias'), 'delete');
                gtm_trackRemoveFromCart(data);
            });
        };

        $button
            .removeClass('is-disabled')
            .addClass('js-remove-' + pseudonym + '-kit')
            .attr('aria-disabled', 'false')
            .attr('tabindex', 0);

        $button.off();
        $button.on('click keydown', function (e) {
            if(is_click_or_key_press(e)) {
                remove_kit();
            }
        });
    }
}

function disable_control_panel(){
    const aliases = ['health', 'ancestry', 'prepaid', 'total-health'];
    aliases.forEach(alias => {
        disable_add_button(alias);
        disable_remove_button(alias);
    });
}

function enable_control_panel (total_kits) {
    // turn off everything before enabling the right stuff
    disable_control_panel();

    const aliases = ['health', 'ancestry', 'prepaid', 'total-health'];

    // enable plus buttons
    total_kits = total_kits || 0;
    if (total_kits < TTAM.MAXIMUM_ALLOWED_KITS_IN_CART) {
        aliases.forEach(alias => enable_add_button(alias));
    }

    // enable remove buttons
    aliases.forEach(alias => enable_remove_button(alias));

    // update counters
    aliases.forEach(alias => {
        // query for the total kits
        const $query = get_alias_query(alias);
        const count = $($query).length;

        // update counter
        const $counter = $(`#text-${alias}-kit-count`);
        $counter.text(count);

        // update a11y attributes
        const currentAriaLabel = $counter.attr('aria-label') || '';
        const updatedAriaLabel = currentAriaLabel.replace(/^\d+/, count);
        $counter.attr('aria-label', updatedAriaLabel);
        $(`#js-quantity-row-list-${alias}`).attr('data-count', count);
    });
}

function enableAgreement() {
    var $shadowAgreement = $('.js-shadow-agreement');
    var $footerAgreement = $('.js-footer-agreement');
    var agreement = $('#id_agreement');
    var error = $('.js-cart-form .error').hide();

    var setError = _.after(1, function() {
        error.show();
        if (agreement.is(':checked')) {
            error.hide();
        }
    });

    // monitor changes to the shadow-agreement checkbox
    // the shadow-agreement checkbox is visible in the mobile-view
    // and mirrors the agreement-checkbox, which is visible in the desktop-view
    $shadowAgreement.change(function(){
        // triggers agreement-checkbox change event
        agreement.trigger('click');
    });

    agreement.change(function() {
        var checked = $(this).is(':checked');
        validate();
        setError();
        // update checked-state for the shadow-agreement and footer-agreement checkboxes
        $shadowAgreement.prop('checked', checked);
        $footerAgreement.prop('checked', checked);
    });

    $checkoutButton.click(function() {
        validate();
        setError();
        if ($checkoutButton.hasClass('button-disabled')) {
            return false;
        }
    });
    $checkoutButton.addClass('button-disabled');
}

function resaleInfo() {
    var more = $('.js-resale-more');
    var details = $('.js-resale-details');
    more.click(function() {
        details.fadeIn('300');
        more.remove();
        return false;
    });
}

function handleGiftInput() {
    // preliminary handling of checkbox on document load
    var $giftCheckbox = $('.js-giftwrap-checkbox');
    var $giftInputFlag = $('.js-cart-form [name=gift]');
    if (($giftCheckbox).is(':checked')) {
        $giftInputFlag.val('true');
    }

    // adjust value of hidden giftwrap input to form if gift option checkbox selected
    $giftCheckbox.on('click', function(){
        if (this.checked) {
            $giftInputFlag.val('true');
        } else {
            // clear all existing gift data if checkbox is unselected
            $giftInputFlag.val('false');
            _.each(kits, function(kit){
                kit.clearGiftValues();
            });
        }
    });
}

function createBraintreeClient () {
    braintree.client.create({
      authorization: TTAM.braintree_token
    }, function (clientErr, clientInstance) {
        if (clientErr) {
            console.error('Error creating braintree client:', clientErr);
            return;
        }
        // set up paypal
        preventEarlyPaypalFormSubmit();
        createBraintreePayment(
            'paypal',
            { client: clientInstance },
            setPaypalClickHandler
        );

        // set up apple pay
        if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
            createBraintreePayment(
                'applePay',
                { client: clientInstance },
                createApplePaymentRequest
            );
        }

        // set up venmo pay
        createBraintreePayment(
            'venmo',
            {
                client: clientInstance,
                paymentMethodUsage: 'multi_use',
                collectCustomerBillingAddress: true,
                collectCustomerShippingAddress: true,
            },
            setVenmoClickHandler
        );
    });
}

function createBraintreePayment (paymentMethod, paramsInstance, callback) {
    braintree[paymentMethod].create(paramsInstance, function (paymentErr, paymentInstance) {
        if (paymentErr) {
            console.error('Error creating ' + paymentMethod + ': ', paymentErr);
            return;
        }
        callback(paymentInstance);
    });
}

function preventEarlyPaypalFormSubmit() {
    $payPalForm.on('submit', function(e) {
        if ($payPalForm.find('[name=payment]').val() !== 'paypal') {
            e.preventDefault();
        }
    });
}

function hasShippingCountryIssue(countryCode) {
    const shipping_valid = TTAM.countries.join(',').includes(countryCode);
    if (!shipping_valid) {
        const COUNTRIES_TO_REGION_MAP = {
            'US': REGION.US,
            'CA': REGION.CA,
            'GB': REGION.GB,
            'DK': REGION.EU,
            'FI': REGION.EU,
            'IE': REGION.EU,
            'NL': REGION.EU,
            'SE': REGION.EU,
        };

        let region = 'en-int';
        if (countryCode in COUNTRIES_TO_REGION_MAP) {
            region = COUNTRIES_TO_REGION_MAP[countryCode];
        } else if (is_international()) {
            const errorModal = $('#js-paypal-shipping-address-error');
            $('#js-paypal-shipping-address-error button').bind('click', function() {
                $('#js-paypal-shipping-address-error button').unbind('click');
                errorModal.css('display', 'none');
            });
            errorModal.css('display', 'block');
            return true;
        }
        const url = 'https://store.23andme.com/' + region + '/cart/';
        const addressModal = $('#js-paypal-shipping-address');
        $('#js-paypal-shipping-address-link').html('<a href="' + url + '">' + url + '</a>');
        $('#js-paypal-shipping-address button').bind('click', function() {
            $('#js-paypal-shipping-address button').unbind('click');
            addressModal.css('display', 'none');
        });
        addressModal.css('display', 'block');
        return true;
    }
    return false;
}

function setVenmoClickHandler (venmoInstance) {
    if (!venmoPayFormEnabled || !venmoInstance.isBrowserSupported()) {
        $venmoPayButton.disabled = true;
        return;
    }

    $venmoPayForm.removeClass('hide');
    $venmoPayForm.toggle(true);
    $venmoPayButton.on('click', function (event) {
        if (kits.length === 0 || !validate(true)) {
            return;
        }

        $venmoPayButton.disabled = true;
        venmoInstance.tokenize({
            processOptions: {
                submitForSettlement: true
            }
        }, function (tokenizeErr, payload) {
            if (tokenizeErr) {
                if (tokenizeErr.code === 'VENMO_CANCELED') {
                    console.log('App is not available or user aborted payment flow');
                } else if (tokenizeErr.code === 'VENMO_APP_CANCELED') {
                    console.log('User canceled payment flow');
                } else {
                    console.error('An error occurred:', tokenizeErr.message);
                }

                $venmoPayForm.find('[name=payment]').val('');
                return;
            }

            eventEmitter.emit('loading');
            submitVenmoForm(payload);
        });
    });
}

function setPaypalClickHandler (paypalInstance) {
    if (!payPalFormEnabled) { return; }
    $payPalForm.removeClass('hide');
    $payPalForm.toggle(true);
    cartv2_scrolling('setPaypalClickHandler');
    $payPalCheckoutButton.on('click', function (event) {
        if (kits.length === 0 || !validate(true)) {
            return;
        }

        // Use 'vault' flow for international regions
        var tokenizationOptions = {
          enableShippingAddress: true,
          flow: 'vault',
        };

        // Use 'checkout' flow for US region (pass total amount; no billing agreement)
        if (TTAM.LANGUAGE_CODE === REGION.US) {
            tokenizationOptions.flow = 'checkout';
            tokenizationOptions.currency = 'USD';
            tokenizationOptions.amount = globalTotal;
        }

        paypalInstance.tokenize(tokenizationOptions, function (tokenizeErr, payload) {
            if (tokenizeErr) {
                if (tokenizeErr.type !== 'CUSTOMER') {
                    console.error('Error tokenizing:', tokenizeErr);
                }
                $payPalForm.find('[name=payment]').val('');
                return;
            }

            const countryCode = payload['details']['shippingAddress']['countryCode'];
            if (hasShippingCountryIssue(countryCode)) {
                return;
            }

            eventEmitter.emit('loading');
            deactivateSubmitButtons(true);
            submitPaypalForm(payload);
        });
    });
}

function submitPaypalForm (payload) {
    $payPalForm.find('[name=payment]').val('paypal');
    $payPalForm.find('[name=details]').val(JSON.stringify(payload.details));
    $payPalForm.find('[name=nonce]').val(payload.nonce);
    if (is_international()) {
        var $shadowAgreement = $('.js-shadow-agreement');
        $payPalForm.find('[name=agreement]').val($shadowAgreement.prop('checked'));
    }

    $payPalForm.submit();
}

function submitVenmoForm (payload) {
    $venmoPayForm.find('[name=payment]').val('venmo');
    $venmoPayForm.find('[name=details]').val(JSON.stringify(payload.details));
    $venmoPayForm.find('[name=nonce]').val(payload.nonce);

    $venmoPayForm.submit();
}

function createApplePaymentRequest (applePayInst) {
    applePayInstance = applePayInst;
    var paymentRequest = applePayInstance.createPaymentRequest({
        lineItems: [],
        total: {
            label: STORE_NAME,
            amount: 1,
            type: 'pending',
        },
        requiredBillingContactFields: ['postalAddress'],
        requiredShippingContactFields: [
            'postalAddress',
            'name',
            'phone',
            'email'
        ]
    });
    setApplePayClickHandler(paymentRequest);
}

function setApplePayClickHandler (paymentRequest) {
    let applePaySessionIsActive = false;

    if (!applePayFormEnabled) { return; }
    $('.checkout-panel-buttons .applepay').removeClass('hide-by-default').show();
    $applePayForm.removeClass('hide');
    $applePayForm.toggle(true);
    $('.applepay-icon').removeClass('hide').parent().removeClass('hide');


    const applePayOnClick = function () {
        if (kits.length === 0 || !validate(true)) {
            return;
        }

        // make sure we don't instantiate two ApplePay sessions at the same time and/or overwrite the old session
        if (!applePaySessionIsActive) {
            applePaySession = new ApplePaySession(2, paymentRequest);
            applePaySession.onvalidatemerchant = applePayValidateMerchant;
            applePaySession.onshippingcontactselected = applePayOnShippingAddressChange;
            applePaySession.onshippingmethodselected = applePayOnShippingMethodChange;
            applePaySession.onpaymentauthorized = applePayAuthorizePayment;
            applePaySession.oncancel = function(event) {
                applePaySessionIsActive = false;
            }
            applePaySession.begin();
            applePaySessionIsActive = true;
        }
    }

    $applePayButton.on('click', applePayOnClick);
}

function applePayValidateMerchant (event) {
    applePayInstance.performValidation({
        validationURL: event.validationURL,
        displayName: '23andMe'
    }, function (validationErr, merchantSession) {
        if (validationErr) {
            console.error('Error validating merchant:', validationErr);
            applePaySession.abort();
            return;
        }
        applePaySession.completeMerchantValidation(merchantSession);
    });
}

function applePayOnShippingAddressChange (event) {
    const applePaySessionOnShippingAddressChangeTimeoutCounter = new Date();
    const failCallback = function () {
        applePaySession.completeShippingContactSelection(
            applePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
            [],
            {
                label: STORE_NAME,
                amount: 1,
                type: 'pending',
            },
            []
        );
    }

    const countryCode = event.shippingContact['countryCode'];
    if (hasShippingCountryIssue(countryCode)) {
        failCallback();
        return;
    }

    setShippingAddress(event.shippingContact, function (data) {
        $('#js-paypal-shipping-address-error').hide();
        $('#js-paypal-shipping-address').hide();
        const currentTime = new Date();
        const elapsedSeconds = (currentTime - applePaySessionOnShippingAddressChangeTimeoutCounter) / 1000;
        if (elapsedSeconds > 30) {
            window && window.newrelic && window.newrelic.noticeError( new Error("applePayOnShippingAddressChange callback hit 30 second timeout limit"));
        } else {
            applePaySession.completeShippingContactSelection(
                applePaySession.STATUS_SUCCESS,
                getApplePayShippingMethods(data.shipping_methods),
                newApplePayTotal(data.totals),
                newApplePayLineItems(data.totals)
            );
        }
    }, failCallback);
}

function applePayOnShippingMethodChange (event) {
    updateShippingMethod(event.shippingMethod, function (data) {
        $applePayForm.find('[name=method]').val(event.shippingMethod.identifier);
        applePaySession.completeShippingMethodSelection(
            applePaySession.STATUS_SUCCESS,
            newApplePayTotal(data.totals),
            newApplePayLineItems(data.totals)
        )
    });
}

function applePayAuthorizePayment (event) {
    applePayInstance.tokenize({
        token: event.payment.token
    }, function (tokenizeErr, payload) {
        if (tokenizeErr) {
            console.error('Error tokenizing Apple Pay:', tokenizeErr);
            applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
            return;
        }
        applePaySession.completePayment(ApplePaySession.STATUS_SUCCESS);
        eventEmitter.emit('loading');
        submitApplePayForm(event.payment, payload.nonce);
    });
}

function submitApplePayForm (payment, nonce) {
    $applePayForm.find('[name=applepay]').val('true');
    $applePayForm.find('[name=details]').val(JSON.stringify(payment));
    $applePayForm.find('[name=nonce]').val(nonce);

    $applePayForm.submit();
}

function newApplePayLineItems(totals) {
    var lineItems = [
        { label: 'Subtotal', amount: displayPrice(totals.items_total_with_discount) },
        { label: 'Shipping', amount: displayPrice(totals.shipping)},
    ];
    if (totals.tax_amount > 0) {
        lineItems.push({ label: 'Sales Tax', amount: displayPrice(totals.tax_amount) });
    }
    return lineItems;
}

function newApplePayTotal(totals) {
    return ({ label: STORE_NAME, amount: displayPrice(totals.total) });
}

function setShippingAddress(address, successCallback, failCallback) {
    $.ajax({
        type: 'POST',
        url: '/' + TTAM.LANGUAGE_CODE + '/applepay/address/',
        data: address,
        dataType: 'json'
    }).done(function(data) {
        successCallback(data);
    }).fail(function() {
        failCallback();
    });
}

function updateShippingMethod(newMethod, callback) {
    $.ajax({
        type: 'PUT',
        url: '/' + TTAM.LANGUAGE_CODE + '/applepay/address/',
        data: newMethod,
        dataType: 'json'
    }).done(function(data) {
        callback(data);
    });
}

function getApplePayShippingMethods (methods) {
    var shipMethods = [];
    _.each(methods, function(method) {
        if (method.code.includes(ANCESTRY_BOOK_SHIPPING_PREFIX)) {
            return;
        }
        var shipMethod = {
            amount: displayPrice(method.total_price),
            detail: method.title,
            identifier: method.code,
            label: SHIPPING_CODE[method.code]
        };
        shipMethods.push(shipMethod);
    });
    return shipMethods;
}

function enableApplepay () {
    if (window.ApplePaySession && ApplePaySession.canMakePayments()) {
        applePayFormEnabled = true;
    }
}

function enablePaypal () {
    payPalFormEnabled = true;
}

function enableVenmo () {
    venmoPayFormEnabled = true;
}

function displayPrice (num) {
    return parseFloat((parseFloat(num)).toFixed(2));
}

function initKits() {
    const components = [
        '.js-health-kit-item',
        '.js-ancestry-kit-item',
        '.js-prepaid-kit-item',
        '.js-total-health-kit-item',
        '.cartv2-item',
    ];
    kits = _.map($(components.join(',')), function(el) {
        return new Kit(el, eventEmitter);
    });
}

// Toggle modal
function toggleVeil(event){
    var veilId = $(event.currentTarget).attr('aria-controls');
    var veil = document.getElementById(veilId);
    var open = $(veil).hasClass('is-open');

    $(veil).toggleClass('is-open', !open).attr('aria-hidden', (open ? 'true' : 'false'));
    $('body').toggleClass('is-veiled', !open);
}

function init () {
    initKits();
    enable_control_panel();
    enableAgreement();
    resaleInfo();
    createBraintreeClient();
    validate();
    handleGiftInput();
    enableSubscriptionUpsell();
    $('.js-toggle-veil').on('click', toggleVeil);

    if (TTAM.enable_paypal) {
        enablePaypal();
    }

    if (TTAM.enable_applepay) {
        enableApplepay();
    }

    if (TTAM.enable_venmo) {
        enableVenmo();
    }

    cartplus.fdaModalSetUp();
    cartplus.initDrawers();
    cartplus.initTabs();

    update_email_capture();

    cartv2_initialize(eventEmitter, initKits);
}

module.exports = init;
