import React, { useEffect } from 'react';
import CcNumber from '../PaymentMethodInputs/ccNumber';
import ExpirationDate from '../PaymentMethodInputs/expirationDate';
import Cvv from '../PaymentMethodInputs/cvv';
import { useStoreContext } from '../../../utils/storeUtils';
import withBraintreeLoader from '../../common/withBraintreeLoader';

interface Props {
  isHosted?: boolean;
  setupHostedFields?: () => void;
}

function HostedFields({ setupHostedFields, isHosted = true }: Props) {
  const { storeState, dispatch } = useStoreContext();
  const { inputFields } = storeState;

  useEffect(() => {
    setupHostedFields();
  }, [setupHostedFields]);

  const className = 'spc-hosted-field';
  const errorClassName = `${className} mod-err`;
  const props = {
    className,
    errorClassName,
    isHosted,
  };
  if (!inputFields) return null;

  return (
    <div className="spc-input-wrap spc-billing-cc-wrap">
      <CcNumber number={inputFields.number} {...props} />
      <div className="spc-input-wrap-text all-widths">
        <ExpirationDate date={inputFields.expirationDate} {...props} />
        <Cvv cvv={inputFields.cvv} {...props} />
      </div>
    </div>
  );
}
HostedFields.displayName = 'HostedFields';
export default withBraintreeLoader(HostedFields);
