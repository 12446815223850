import React from 'react';
import AddressDisplay from '../common/AddressDisplay';
import { STEP } from '../../constantsv2';
import { useStoreContext } from '../../utils/storeUtils';
import { goToStep } from '../../actions/thunks';
import { EXPERIMENT_C_VARIANTS } from '../../constants';
interface Props {
  address: Address;
}

const AddressInfo = (props: Props) => {
  const { storeState, dispatch } = useStoreContext();
  const { email, phone } = props.address;

  const editableClass =
    storeState.paymentMethod === 'applepay' ? ' mod-hide' : storeState.editableClass;

  const isExperimentC = EXPERIMENT_C_VARIANTS.includes(storeState.expCCartCheckoutCleanup);

  return (
    <div className="spc-wrap">
      <div className={`spc-align${isExperimentC ? ' exp-c' : ' mod-border'}`}>
        <h2 className="spc-align-h2 spc-count" data-stor-id="spc-address-header">
          {!isExperimentC && '1. '}Shipping{isExperimentC && ' Address'}
        </h2>
        <button
          className={`spc-button-edit${editableClass}`}
          onClick={e => {
            e.stopPropagation();
            dispatch(goToStep(STEP.ADDRESS));
          }}
          type="button"
        >
          {isExperimentC ? 'Edit' : 'EDIT'}
        </button>
      </div>
      <div className="spc-info mod-border">
        <AddressDisplay
          firstName={props.address.firstName}
          lastName={props.address.lastName}
          company={props.address.company}
          address={props.address.address}
          address2={props.address.address2}
          city={props.address.city}
          state={props.address.state}
          postalCode={props.address.postalCode}
          country={props.address.country}
        />
      </div>
      <div className="spc-info">
        <div className="spc-info-details">
          <div className="spc-info-details-title">YOUR CONTACT INFO</div>
          <div className="spc-info-details-wrap">
            <div>{email}</div>
            <div>{phone}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddressInfo.displayName = 'AddressInfo';

export default AddressInfo;
