import React from 'react';
import withAnim from '../common/withAnim';
import { BILLING_ADDRESS, BILLING_METHODS } from '../../constants';
import PaymentMethodInfo from './PaymentMethodInfo';

interface Props {
  addressSource: number;
  animClass: string;
  cardType: string;
  lastFour: string;
  customAddress: Address;
  method: BillingMethods;
  savedPayment: SavedPayment;
  shippingAddress: Address;
}

const BillingInfo = ({
  addressSource,
  animClass,
  cardType = '',
  lastFour = '',
  customAddress,
  method,
  savedPayment = {
    cardType: '',
    lastFour: '',
    imageUrl: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
    expired: false,
  },
  shippingAddress,
}: Props) => {
  const useCustomAddress =
    addressSource === BILLING_ADDRESS.CUSTOM || addressSource === BILLING_ADDRESS.SAVED_CUSTOM;
  // savedPayment consists of both payment and billing address flattened together
  const defaultAddress = method === BILLING_METHODS.SAVED_PAYMENT ? savedPayment : shippingAddress;
  const displayAddress = useCustomAddress ? customAddress : defaultAddress;
  const {
    firstName,
    lastName,
    address,
    address2,
    city,
    state,
    postalCode,
    country,
    company,
    //work around address2/company might not exist
  } = displayAddress as any;

  return (
    <div className={`${animClass}`}>
      <PaymentMethodInfo
        {...{
          firstName,
          lastName,
          company,
          address,
          address2,
          city,
          state,
          postalCode,
          country,
          method,
          cardType,
          lastFour,
        }}
      />
    </div>
  );
};

BillingInfo.displayName = 'BillingInfo';

export default withAnim(BillingInfo);
