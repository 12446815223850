import React, { useState, useEffect } from 'react';
import Checkbox from '../../common/Checkbox';
import handleKeyDown from '../../../utils/handleKeyDown';
import { useStore } from '../../../utils/storeUtils';
import { BILLING_METHODS } from '../../../constants';
import SubscriptionName from './../../SubscriptionPayment/SubscriptionName';

interface Props {
  hasSavedCreditCard: boolean;
  isAuthenticated: boolean;
  isEditHostedFields: boolean;
  method: BillingMethods;
  saveCreditCardOptIn: boolean;
  setSavePayment;
  isApiUp: boolean;
  hasTotalHealthUpgrade: boolean;
  displayRequiredError: boolean;
}

const toggleSavedPayment = (props, callback) => {
  if (!props.isAuthenticated) {
    callback(true);
  } else {
    props.setSavePayment(!props.saveCreditCardOptIn);
  }
};

function ConfirmSavePayment(props: Props) {
  const [_, updateStoreState] = useStore();
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  useEffect(() => {
    if (props.isAuthenticated) {
      setShowAuthPrompt(false);
    }
  }, [props.isAuthenticated]);

  const { method, isEditHostedFields, hasSavedCreditCard } = props;
  const isMethodCreditCard = method === BILLING_METHODS.CREDIT_CARD;
  const isSavedPaymentWithEdit = method === BILLING_METHODS.SAVED_PAYMENT && isEditHostedFields;
  const displayCheckbox = props.isApiUp && (isMethodCreditCard || isSavedPaymentWithEdit);
  if (!displayCheckbox) {
    return null;
  }

  const { hasTotalHealthUpgrade, displayRequiredError } = props;

  const label = isMethodCreditCard
    ? 'Save my payment information for future orders'
    : 'Replace saved payment information';
  const requiredTag = hasTotalHealthUpgrade ? ' (required)' : '';

  const ariaLabel = props.isAuthenticated
    ? label + requiredTag
    : `${label}. Sign in with your 23andMe account to use this feature.`;

  let legalDisclaimer = (
    <>
      23andMe does not store your payment information. It is stored by a third party payment
      processor. You may update your payment information at any time.
    </>
  );
  if (hasTotalHealthUpgrade) {
    legalDisclaimer = (
      <>
        Check this box to continue with your order and to save this payment method for all future
        transactions. You can change this payment method at any time in your account settings and
        you can cancel your subscription any time. Your payment information is stored by a third
        party payment processor. 23andMe does not store your payment information.
      </>
    );
    if (hasSavedCreditCard) {
      legalDisclaimer = (
        <>
          Check this box to continue with your order and to save this payment method for all future
          transactions. This will replace previously saved payment information when your new
          membership starts. You can change this payment method at any time in your account settings
          and you can cancel your subscription any time. Your payment information is stored by a
          third party payment processor. 23andMe does not store your payment information.
        </>
      );
    }
  }

  const required = hasTotalHealthUpgrade && (
    <span className="spc-billing-confirm-update-red">(required)</span>
  );

  return (
    <div className="spc-billing-next">
      <div className="spc-billing-confirm-update-root">
        {displayRequiredError && (
          <div
            role="alert"
            className="spc-billing-confirm-update-wrappe spc-billing-confirm-update-required"
            data-stor-id="spc-billing-confirm-alert-message"
          >
            A saved payment method is required to complete your{' '}
            <SubscriptionName isTotalHealth={true} /> upgrade purchase.
          </div>
        )}
        <div className="spc-billing-confirm-update-wrapper">
          <Checkbox
            checked={props.saveCreditCardOptIn}
            dataStorId="spc-confirm-saved-payment"
            handleCheck={() => toggleSavedPayment(props, setShowAuthPrompt)}
            ariaLabel={ariaLabel}
            ariaDisabled={!props.isAuthenticated}
            id="save-payment-confirmation-checkbox"
          />
          <label
            className="spc-billing-confirm-update-label"
            tabIndex={-1}
            htmlFor="save-payment-confirmation-checkbox"
            aria-hidden={true}
          >
            {isMethodCreditCard ? (
              <>Save my payment information for future orders {required}</>
            ) : (
              <>Replace saved payment information {required}</>
            )}
            {props.hasSavedCreditCard && method === BILLING_METHODS.CREDIT_CARD && (
              <span>
                <br />
                (overwrites previously saved information)
              </span>
            )}
          </label>
        </div>
        {showAuthPrompt && (
          <div className="spc-billing-confirm-update-authentication">
            This feature is only available for customers with 23andMe accounts. To use this feature,{' '}
            <button
              className="spc-billing-confirm-update-basic-link spc-button-link"
              onClick={e => {
                e.preventDefault();
                updateStoreState({ modalOpen: 'AUTH' });
              }}
              onKeyDown={handleKeyDown}
              type="button"
              tabIndex={0}
            >
              sign in
            </button>{' '}
            or create an account.
          </div>
        )}
        <div className="spc-billing-confirm-update-disclaimer">{legalDisclaimer}</div>
      </div>
    </div>
  );
}

export default ConfirmSavePayment;
