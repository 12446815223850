import React from 'react';

interface Props {
  disabledBtnClass?: string;
  accepted?: boolean;
  ariaDisabled?: boolean;
  isExperimentC?: boolean;
}

const OrderButtonAll = ({
  disabledBtnClass = '',
  accepted = false,
  ariaDisabled = false,
  isExperimentC = false,
}: Props) => {
  const acceptedClassName = accepted
    ? 'spc-summary-accept-button spc-next-button mod-ok'
    : 'spc-summary-accept-button spc-next-button';

  const buttonLabel = disabledBtnClass ? 'Please Wait' : 'Submit Order';

  return (
    <div className="spc-summary-accept-button-wrap">
      <button
        className={`${acceptedClassName} ${disabledBtnClass}`}
        data-stor-id="spc-submit-order-button"
        data-track-click="checkout_order_button"
        type="submit"
        aria-label="submit order"
        aria-disabled={!accepted}
      >
        {isExperimentC ? buttonLabel : buttonLabel.toLowerCase()}
      </button>
    </div>
  );
};

export default OrderButtonAll;
