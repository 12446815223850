import React, { useEffect, useState } from 'react';
import BillingAddress from './BillingAddress';
import PaymentMethodOption from './PaymentMethodOption';
import BillingSubmitButton from './BillingSubmitButton';
import withAnim from '../common/withAnim';
import withBraintree from './withBraintree';
import { ELEMENTS, BILLING_METHODS } from '../../constants';
import { scroller } from '../../utils/scroller';
import { getListOfInvalidFields } from '../../utils/getListOfInvalidFields';
import { refineAddressValidation } from '../../utils/i18nFields';
import PaymentMethodBody from './PaymentMethodBody';
import ConfirmSavePayment from './ConfirmSavePayment';
import { CUSTOM_ADDRESS_SOURCES } from '../../constantsv2';

interface Props {
  addressSource: number;
  animClass: string;
  countries: Country[];
  customAddress: Address;
  disableCountrySelector: boolean;
  fieldInfo: any;
  handleSubmit: (ccInfo: any) => void;
  handleChange: any;
  handleFieldChange: any;
  handleSubmitHostedFields: any;
  handleSubmitPaypal: any;
  handleSubmitVenmo: any;
  isAuthenticated: boolean;
  isCustomAddressValidated: boolean;
  isEditHostedFields?: boolean;
  isPayPalReady?: boolean;
  method?: BillingMethods;
  saveCreditCardOptIn: boolean;
  savedPayment: SavedPayment;
  setEditHostedFields: any;
  setupHostedFields: any;
  setSavePayment: (bool: boolean) => void;
  shippingAddress: Address;
  states: GeoState[];
  removeSavePayment: any;
  hasSavedCreditCard?: boolean;
  switchToCustomAddress: (addressType: string) => void;
  isApiUp: boolean;
  hasTotalHealthUpgrade: boolean;
  displayRequiredError: boolean;
  paypalEnabled: boolean;
  venmoEnabled: boolean;
  isExperimentC?: boolean;
}

function BillingForm({
  setSavePayment,
  addressSource,
  animClass = '',
  countries,
  customAddress = {},
  disableCountrySelector = false,
  fieldInfo = [],
  handleSubmit,
  handleChange,
  handleFieldChange,
  handleSubmitHostedFields,
  handleSubmitPaypal,
  handleSubmitVenmo,
  isAuthenticated,
  isCustomAddressValidated,
  isEditHostedFields,
  isPayPalReady = true,
  method,
  saveCreditCardOptIn,
  savedPayment = {
    cardType: '',
    lastFour: '',
    imageUrl: '',
    expired: false,

    // saved payment consists of payment and address data
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  },
  setEditHostedFields,
  setupHostedFields,
  shippingAddress = {},
  states,
  removeSavePayment,
  hasSavedCreditCard = false,
  switchToCustomAddress,
  isApiUp,
  hasTotalHealthUpgrade,
  displayRequiredError,
  paypalEnabled,
  venmoEnabled,
  isExperimentC = false,
}: Props) {
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    setSavePayment(false);
  }, []);

  const clearErr = errName => {
    if (errors.length > 0) {
      setErrors(errors.filter(name => name !== errName));
    }
  };

  // can probably leave this out for now
  const hasAddressIssues = () => {
    if (CUSTOM_ADDRESS_SOURCES.includes(addressSource)) {
      const errors = getListOfInvalidFields(customAddress, refineAddressValidation(fieldInfo));

      if (errors.length > 0) {
        setErrors(errors.slice());
        scroller(`#${ELEMENTS[errors[0]]}`, 200, -150);
        return true;
      }
    }

    return false;
  };

  const handleSubmitSavedPayment = callback => {
    callback({
      nonce: '',
      lastFour: savedPayment ? savedPayment.lastFour : '',
      cardType: savedPayment ? savedPayment.cardType : '',
    });
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (hasAddressIssues()) {
      return;
    }

    let handleSavedPayment = handleSubmitSavedPayment;
    const expired = hasSavedCreditCard ? savedPayment.expired : false;
    if (isEditHostedFields) {
      handleSavedPayment = handleSubmitHostedFields;
    } else if (method == BILLING_METHODS.SAVED_PAYMENT && expired) {
      return;
    }

    const submitForm = {
      [BILLING_METHODS.CREDIT_CARD]: handleSubmitHostedFields,
      [BILLING_METHODS.PAYPAL]: handleSubmitPaypal,
      [BILLING_METHODS.SAVED_PAYMENT]: handleSavedPayment,
      [BILLING_METHODS.VENMO]: handleSubmitVenmo,
    };
    submitForm[method](params => handleSubmit(params));
  };

  const handleEditSavedPayment = (defaultSetting = true) => {
    setEditHostedFields(defaultSetting);
  };

  const isExpiredCard = hasSavedCreditCard ? savedPayment.expired : false;

  return (
    <form className={`spc-form spc-billing-cc ${animClass}`} noValidate onSubmit={handleFormSubmit}>
      <div className="spc-title" role="radiogroup" aria-labelledby="payment-title">
        <h3
          id="payment-title"
          className={`spc-title-h3${isExperimentC ? ' spc-visually-hidden' : ''}`}
        >
          PAYMENT METHOD
        </h3>
        <PaymentMethodOption
          rootClass="spc-billing-options"
          {...{
            handleChange,
            method,
            savedPayment,
            paypalEnabled,
            venmoEnabled,
          }}
        />
      </div>
      <PaymentMethodBody
        handleEditSavedPayment={handleEditSavedPayment}
        rootClass="spc-billing-cc-inputs"
        {...{
          method,
          savedPayment,
          removeSavePayment,
          setupHostedFields,
        }}
      />
      <BillingAddress
        clearErr={clearErr}
        errors={errors}
        {...{
          addressSource,
          countries,
          customAddress,
          disableCountrySelector,
          fieldInfo,
          handleFieldChange,
          method,
          savedPayment,
          shippingAddress,
          states,
          switchToCustomAddress,
          isExperimentC,
        }}
      />
      <ConfirmSavePayment
        {...{
          hasSavedCreditCard,
          isAuthenticated,
          isEditHostedFields,
          method,
          saveCreditCardOptIn,
          setSavePayment,
          isApiUp,
          hasTotalHealthUpgrade,
          displayRequiredError,
        }}
      />
      <BillingSubmitButton
        {...{
          addressSource,
          isCustomAddressValidated,
          isEditHostedFields,
          isPayPalReady,
          method,
          isExpiredCard,
          isExperimentC,
        }}
      />
    </form>
  );
}

export default withAnim(withBraintree(BillingForm));
