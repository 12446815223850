/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ELEMENTS } from '../../../constants';
import { useStore } from '../../../utils/storeUtils';

interface Props {
  number: PaymentInputField;
  className: string;
  errorClassName: string;
  isHosted: boolean;
}

export default function CcNumber({ number, className, errorClassName, isHosted }: Props) {
  const label = 'Card Number';
  let sample = 'e.g. 1111 1111 1111 1111';
  let errClass = className;
  if (number.showError) {
    if (number.isEmpty) {
      sample = `${label} is required`;
    } else if (!number.isValid) {
      sample = `Invalid ${label}`;
    } else {
      sample = `${label} has unknown error`;
    }
    errClass = errorClassName;
  }
  const [_, updateStoreState] = useStore();
  const onChange = e => {
    updateStoreState({ ccNumber: e.target.value });
  };
  const input = isHosted ? (
    <div id={ELEMENTS.ccNum} className={`${errClass} mod-no-top-margin`} />
  ) : (
    <input id={ELEMENTS.ccNum} onChange={onChange} className={`${errClass} mod-no-top-margin`} />
  );
  return (
    <div className="spc-input">
      <div className="spc-input-container">
        <div className="spc-input-label spc-visually-hidden">
          <label htmlFor="card-number" className="spc-input-label-num">
            <span>{label}</span>
          </label>
        </div>
        {input}
        {number.showError && <p className="validation-error">{sample}</p>}
      </div>
    </div>
  );
}
