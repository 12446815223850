/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ELEMENTS } from '../../../constants';
import { useStore } from '../../../utils/storeUtils';

interface Props {
  cvv: PaymentInputField;
  className: string;
  errorClassName: string;
  isHosted: boolean;
}

export default function Cvv({ cvv, className, errorClassName, isHosted }: Props) {
  const label = 'CVV';
  let sample = 'e.g. 111';
  let errClass = className;
  if (cvv.showError) {
    if (cvv.isEmpty) {
      sample = `${label} is required`;
    } else if (!cvv.isValid) {
      sample = `Invalid ${label}`;
    } else {
      sample = `${label} has unknown error`;
    }
    errClass = errorClassName;
  }
  const [_, updateStoreState] = useStore();
  const onChange = e => {
    updateStoreState({ cvv: e.target.value });
  };
  const input = isHosted ? (
    <div id={ELEMENTS.ccCVV} className={`${errClass} mod-no-top-margin`} />
  ) : (
    <input id={ELEMENTS.ccCVV} onChange={onChange} className={`${errClass} mod-no-top-margin`} />
  );
  return (
    <>
      <div className="spc-input">
        <div className="spc-input-container">
          <div className="spc-input-label spc-visually-hidden">
            <label htmlFor="cvv" className="spc-input-label-cvv">
              <span>{label}</span>
            </label>
          </div>
          {input}
          {cvv.showError && <p className="validation-error">{sample}</p>}
        </div>
      </div>
      <div className="spc-input" style={{ flexGrow: 0 }}>
        <div className="spc-tooltip mod-right">
          <span
            data-placement="right"
            data-trigger="hover"
            className="tooltip mod-select spc-tooltip-a"
            aria-describedby="tooltip-cvv"
            tabIndex={0}
            role="button"
            aria-disabled="true"
            aria-label="cvv help"
          >
            <span className="tooltip-span" />
            <span aria-hidden="true">?</span>
            <div className="tooltip-data cvv" id="tooltip-cvv" role="tooltip">
              For VISA, MasterCard and Discover, the CVV security is the 3 digit number after your
              signature on the back of your card. For American Express, it is the 4-digit code on
              the upper right front of your card.
            </div>
          </span>
        </div>
      </div>
    </>
  );
}
