import React from 'react';
import { useStore } from '../../utils/storeUtils';
import TextInput from '../common/TextInput';
import AddressFieldsGroup from '../common/intl/AddressFieldsGroup/AddressFieldsGroup';
import ContactPrivacyGroup from '../common/intl/ContactPrivacyGroup/ContactPrivacyGroup';
import withAnim from '../common/withAnim';
import { ELEMENTS, EXPERIMENT_C_VARIANTS } from '../../constants';
import { REGION, HEAP_REGIONS } from '../../regions';
import { CaptureEmailDisclaimer } from './CaptureEmailDisclaimer';

interface Props {
  animClass: string;
  address: Address;
  errors?: string[];
  disabledBtnClass: string;
  disableCountrySelector?: boolean;
  isReadyForContinue?: boolean;
  handleChange: (event: any) => void;
  handleSubmit: (event: any) => void;
  handleCheckBox?: (e: boolean) => void;
  fieldInfo?: any;
  okayToSendEmail?: boolean;
}

const AddressForm = ({
  address,
  fieldInfo = [],
  isReadyForContinue = false,
  animClass = '',
  disableCountrySelector = false,
  errors = [],
  handleChange = () => {},
  handleSubmit = () => {},
  disabledBtnClass,
  handleCheckBox,
  okayToSendEmail,
}: Props) => {
  const [state] = useStore();

  const showContinue = isReadyForContinue ? ' mod-ok' : '';

  const AddressFields = AddressFieldsGroup[REGION];
  const ContactPrivacy = ContactPrivacyGroup[REGION];
  const isCaptureEmailRegion = HEAP_REGIONS.includes(REGION);
  const CaptureEmailAsterisk = isCaptureEmailRegion ? '*' : '';
  const isExperimentC = EXPERIMENT_C_VARIANTS.includes(state.expCCartCheckoutCleanup);
  const nextButtonText = disabledBtnClass ? 'Please Wait' : 'Continue to Shipping Method';

  return (
    <form className="spc-wrap" data-testid="addressForm" noValidate onSubmit={handleSubmit}>
      <div className={`spc-align${isExperimentC ? ' exp-c' : ' mod-border'}`}>
        <h2
          id={ELEMENTS.addressSection}
          tabIndex={-1}
          className={`spc-align-h2${isExperimentC ? ' exp-c' : ''} spc-count`}
          data-stor-id="spc-address-header"
        >
          {!isExperimentC && '1. '}Shipping{isExperimentC && ' Address'}
        </h2>
        <span className="spc-step">Step 1 of 3</span>
      </div>
      <div
        className={`spc-address-form ${animClass}`}
        role="group"
        aria-labelledby={isExperimentC ? ELEMENTS.addressSection : 'shipping-address-label'}
      >
        <h3 id="shipping-address-label" className={`spc-title${isExperimentC ? ' exp-c' : ''}`}>
          {!isExperimentC && 'SHIPPING ADDRESS'}
        </h3>
        <AddressFields
          states={state.shippingStates}
          countries={state.shippingCountries}
          address={address}
          handleChange={handleChange}
          errors={errors}
          fieldInfo={fieldInfo}
          forShipping
          disableCountrySelector={disableCountrySelector}
          readOnly={state.isChipUpgrade || state.hasTotalHealthUpgrade}
          isBookPurchase={state.isBookPurchase}
        />
      </div>
      <br />
      <div className="spc-contact">
        <div className="spc-title">
          <h3 className="spc-title-h3">{isExperimentC ? 'Contact' : 'YOUR CONTACT INFO'}</h3>
          <div className="spc-title-div">
            {!isExperimentC && (
              <>
                <p>Email address is for order confirmation.</p>
                <p>
                  Phone number is needed if an order processing or delivery issue occurs, as a
                  representative must be able to reach you.
                </p>
              </>
            )}
            <ContactPrivacy />
          </div>
        </div>
        <div className="spc-input-wrap">
          <TextInput
            id={ELEMENTS.email}
            name="email"
            text={`Email${CaptureEmailAsterisk}`}
            value={address.email}
            onChange={handleChange}
            showError={errors.includes('email')}
            readOnly={state.isChipUpgrade || state.hasTotalHealthUpgrade}
            type="email"
          />
          {isCaptureEmailRegion ? (
            <CaptureEmailDisclaimer
              handleCheckBox={handleCheckBox}
              region={REGION}
              okayToSendEmail={okayToSendEmail}
            />
          ) : (
            ''
          )}
          <TextInput
            name="phone"
            value={address.phone}
            onChange={handleChange}
            id={ELEMENTS.phone}
            showError={errors.includes('phone')}
            text={isExperimentC ? 'Phone' : 'Phone**'}
            type="phone"
          />
          {isExperimentC ? (
            <p className="spc-email-confirmation-instructions">
              We'll email your order confirmation to you. If there's an issue with processing or
              delivery, a representative will call you.
            </p>
          ) : (
            <div className="spc-phone-help-text">
              **Phone number is required. If your phone number is outside of the US or Canada,
              please include your country dial code.
            </div>
          )}
        </div>
      </div>
      <div className="spc-next">
        <button
          className={`spc-next-button${showContinue}${disabledBtnClass}`}
          data-stor-id="spc-address-continue-button"
          data-track-click="checkout_address_button"
          aria-label="continue to shipping method"
          type="submit"
          aria-disabled={!isReadyForContinue}
        >
          {isExperimentC ? nextButtonText : nextButtonText.toLowerCase()}
        </button>
      </div>
    </form>
  );
};

export default withAnim(AddressForm);
