import * as React from 'react';
import withAnim from '../common/withAnim';
import { STEP, EXPERIMENT_C_VARIANTS } from '../../constants';
import { useStoreContext } from '../../utils/storeUtils';

import { goToStep } from '../../actions/thunks';

interface Props {
  animClass: string;
  className: string;
}

function ShippingInfo(props: Props) {
  const { storeState, dispatch } = useStoreContext();

  const { animClass, className } = props;

  const { shipMethod, expCCartCheckoutCleanup } = storeState;

  const isExperimentC = EXPERIMENT_C_VARIANTS.includes(expCCartCheckoutCleanup);

  return (
    <div className="spc-wrap">
      <div className={`spc-align${isExperimentC ? ' exp-c' : ' mod-border'}`}>
        <h2 className="spc-align-h2 spc-count" data-stor-id="spc-shipping-method-header">
          {!isExperimentC && '2. '}Shipping Method
        </h2>
        <button
          className={`spc-button-edit${className}`}
          type="button"
          onClick={() => {
            dispatch(goToStep(STEP.SHIP_METHOD));
          }}
        >
          {isExperimentC ? 'Edit' : 'EDIT'}
        </button>
      </div>
      <div className={`spc-info ${isExperimentC && 'mod-exp-c-cart-checkout-cleanup'}`}>
        <div className={`spc-info-details ${animClass}`}>
          {!isExperimentC ? (
            <div className="spc-info-details-title">SELECTED SHIPPING</div>
          ) : (
            <img alt="" src="/static/img/icons/ic_ship.svg" width="24" height="26" />
          )}
          <div className="spc-info-details-wrap">
            {shipMethod.title}
            <span className="spc-info-details-wrap-span">{shipMethod.displayPrice}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withAnim(ShippingInfo);
