import React from 'react';
import { useStoreContext } from '../../utils/storeUtils';
import ClinicHeartCheckup from '../Svgs/ClinicHeartCheckup';
import ClinicCardiometabolicScreening from '../Svgs/ClinicCardiometabolicScreening';

export const YourOrderSection = () => {
  const {
    storeState: {
      cart: { kits: labItems, taxes: taxItems },
      clinic: { firstName, lastName, labels },
    },
  } = useStoreContext();

  const clinicIcons = {
    'US-QUEST-ESSENTIAL-HEART-CHECKUP': <ClinicHeartCheckup />,
    'US-QUEST-ADVANCED-CARDIOMETABOLIC-SCREENING': <ClinicCardiometabolicScreening />,
    // Add more sku to icon mapping here?
  };

  const displayKitPrice = (kit: Kit) => {
    if (!kit.discount) {
      return (
        <div className="spc-order-price-text" data-jest-id="spc-order-price-text" role="cell">
          {kit.displayPrice}
        </div>
      );
    } else {
      return (
        <div className="spc-order-price-text" id="discount-amount">
          <span
            className="spc-order-line-through"
            data-jest-id="spc-order-line-through"
            aria-hidden="true"
          >
            {kit.displayPrice}
          </span>
          <div
            className="spc-discounted-price-text mod-discount"
            data-jest-id="spc-discounted-order-price-text"
            role="cell"
          >
            {kit.displayDiscountPrice}
            <span aria-labelledby="discount-amount" />
          </div>
        </div>
      );
    }
  };

  const items = labItems.map((kit: Kit) => {
    const clinicIcon = clinicIcons[kit.sku] || null;
    return (
      <div key={kit.kitId} data-stor-id="clinic-cart-item-card">
        <h3 className="spc-labs-h2 spc-labs-h2-icon">
          <div className="wide-header">{kit.description}</div>
          <div>{clinicIcon}</div>
        </h3>
        <p>{labels}</p>
        <p>Clinical analysis and consultation</p>
        <p>
          {firstName} {lastName}
        </p>
        <div className="spc-labs-price" role="row">
          {displayKitPrice(kit)}
        </div>
      </div>
    );
  });

  const taxes = taxItems.map((tax, idx) => (
    <div key={idx} className="spc-labs-price">
      {tax['name']}: {tax['price']}
    </div>
  ));

  return (
    <div className="spc-labs-your-order-section">
      {items}
      {taxes}
    </div>
  );
};
